// WaGen___HASHKEY__2952a855_2020-03-30 16:19:28 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
用户纳税信息
*/
/* ---
标准CRUD方法：添加记录
*/
export function yhNsxx_add(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/add',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : String
*/
export function yhNsxx_update(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/update',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : String
*/
export function yhNsxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/remove',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function yhNsxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveDelete',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function yhNsxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveUpdate',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function yhNsxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveAdd',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : String
*/
export function yhNsxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/fetch',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function yhNsxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/fetchAll',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhNsxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveGet',
		method : 'post',
		data : {
			param : entity, //YhNsxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhNsxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function yhNsxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveCount',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}

/* ---
多记录插入
*/
export function yhNsxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/insertList',
		method : 'post',
		data : {
			param : list //List<YhNsxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : String
*/
export function yhNsxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/updateList',
		method : 'post',
		data : {
			param : list //List<YhNsxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : String
*/
export function yhNsxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/deleteList',
		method : 'post',
		data : {
			param : list //List<YhNsxx>
		}
	})
}

