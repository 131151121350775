<template>
  <page-view :title="title" left-arrow white-bg>
    <van-form @submit="onSubmit">
      <van-field name="type" label="发票抬头类型">
        <template #input>
          <van-radio-group v-model="type" direction="horizontal">
            <van-radio name="1">个人</van-radio>
            <van-radio name="2">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="type == '1'">
        <van-field name="yhmc" label="发票抬头" placeholder="请填写发票抬头" v-model="person.yhmc"
          :rules="[{ required: true, message: '请填写发票抬头'}]" required/>
        <van-field name="zjbh" label="身份证号" placeholder="请填写身份证号" v-model="person.zjbh"
          :rules="[{ required: true, message: '请填写身份证号'}]" required/>
        <van-field name="lxsj" label="联系手机" placeholder="请填写联系手机" v-model="person.lxsj"
          :rules="[{ required: true, message: '请填写联系手机'}]" required/>
        <van-field name="yhdz" label="联系地址" placeholder="联系地址" v-model="person.yhdz" />
        <van-field name="email" label="电子邮箱" placeholder="电子邮箱" v-model="person.email" />
      </div>
      <div v-else>
        <van-field name="nsrmc" label="发票抬头" placeholder="请填写抬头名称" v-model="company.nsrmc"
          :rules="[{ required: true, message: '请填写抬头名称'}]" required/>
        <van-field name="nsbzmc" label="纳税标志" placeholder="请选择纳税标志" v-model="company.nsbzmc"
          :rules="[{ required: true, message: '请选择纳税标志'}]" required readonly @click="nsbzShow = true;"/>
        <van-field name="nsrsbh" label="纳税人识别号" placeholder="请填写纳税人识别号" v-model="company.nsrsbh"
          :rules="[{ required: true, message: '请填写纳税人识别号'}]" required/>
        <van-field name="nsrdz" label="纳税人地址" placeholder="请填写纳税人地址" v-model="company.nsrdz"
          :rules="[{ required: true, message: '请填写纳税人地址'}]" required/>
        <van-field name="nsrdh" label="纳税人电话" placeholder="请填写纳税人电话" v-model="company.nsrdh"
          :rules="[{ required: true, message: '请填写纳税人电话'}]" required/>
        <van-field name="nsrkhh" label="开户银行" placeholder="请填写开户银行" v-model="company.nsrkhh"
          :rules="[{ required: true, message: '请填写开户银行'}]" required/>
        <van-field name="nsryhzh" label="银行账号" placeholder="请填写银行账号" v-model="company.nsryhzh"
          :rules="[{ required: true, message: '请填写银行账号'}]" required/>
        <van-field name="frxm" label="法人姓名" placeholder="法人姓名" v-model="company.frxm" />
        <van-field name="frdh" label="法人电话" placeholder="法人电话" v-model="company.frdh" />
      </div>
      <van-popup v-model="nsbzShow" round position="bottom">
        <van-picker
          title="标题"
          show-toolbar
          :columns="nsbzArray"
          @confirm="bindNSBZChange"
        />
      </van-popup>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">保存</van-button>
      </div>
    </van-form>

    
  </page-view>
</template>

<script>
  import {
    Radio,
    RadioGroup
  } from 'vant';
  import {yhJbxx_fetch,yhJbxx_selectiveUpdate} from '@/api/psdmsykgl/dawhschema/YhJbxxAPI';
  import {yhNsxx_add,yhNsxx_update,yhNsxx_selectiveGet,yhNsxx_remove} from '@/api/psdmsykgl/dawhschema/YhNsxxAPI';
  export default {
    name: 'InvoiceTitleEdit',
    components: {
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup
    },
    data() {
      return {
        title: '发票抬头维护',
        type: '1',
        nsbzArray: [{key:1,text:'1-企业（一般纳税人）'}, {key:2,text:'2-企业（小规模纳税人）'}, {key:3,text:'3-政府行政事业单位'}],
        nsbzShow:false,
        isNsxx:false,
        company:{
          nsbz:'',
          frxm:'',
          frdh:'',
        },
        person:{
          yhdz:'',
          email:''
        },
        yhjbxx:{}
      }
    },
    async created() {
      this.$toast.loading('加载中...')
      await this.getNsxx();
      this.$toast.clear();
    },
    methods: {
      onSubmit(values) {
        if(this.type==1){
          this.updateNsbz();
        }else{
          this.submitNsxx(this.company);
        }
      },
      bindNSBZChange(e) {
        this.company.nsbz = e.key;
        this.company.nsbzmc = e.text;
        this.nsbzShow = false;
      },
      async getNsxx(){
        let jbxxRes = await yhJbxx_fetch({yhbh: this.$route.query.yhbh});
        if(jbxxRes.code == 200){
          this.yhjbxx = jbxxRes.content;
          this.person = this.yhjbxx;
          if(this.yhjbxx.nsbz == 0){
            this.company = {};
          }else{
            this.type = '2';
          }
          let res = await yhNsxx_selectiveGet({yhbh: this.$route.query.yhbh});
          if(res.code == 200){
            if (res.content != '' && res.content != null && res.content.length!=0) {
              this.isNsxx=true //有纳税信息
              this.company = res.content[0];
              this.company.nsbz = this.yhjbxx.nsbz;
              this.nsbzArray.forEach((item,index)=>{
                if(item.key==this.yhjbxx.nsbz){
                  this.company.nsbzmc = item.text;
                }
              })
            }else {
              this.isNsxx=false //无纳税信息
            }
          }
        }
      },
      async submitNsxx(param){
        param = {
          ...param,
          yhbh:this.yhjbxx.yhbh,
          jgbm:this.yhjbxx.jgbm,
          fqbm:this.yhjbxx.fqbm,
          czry:this.yhjbxx.czry,
          czsj:this.yhjbxx.czsj,
        }
        if (this.isNsxx) {
          let res = await yhNsxx_update(param);
          if(res.code == 200){
            this.$toast.success('纳税信息修改成功');
            await this.updateNsbz();
          }
        } else {
          let res = await yhNsxx_add(param);
          if(res.code == 200){
            this.$toast.success('纳税信息新增成功');
            await this.updateNsbz();
          }else{
            this.$toast.fail('纳税信息新增失败'+res.message);
          }
        }
      },
      async updateNsbz(){
        if(this.type=='2'){
          this.yhjbxx.nsbz = this.company.nsbz;
        }else{
          this.yhjbxx.nsbz = 0;
          this.yhjbxx.yhmc = this.person.yhmc;
          this.yhjbxx.email = this.person.email;
          this.yhjbxx.zjbh = this.person.zjbh;
          this.yhjbxx.lxsj = this.person.lxsj;
          this.yhjbxx.yhdz = this.person.yhdz;
        }
        let res = await yhJbxx_selectiveUpdate(this.yhjbxx);
        if(res.code != 200){
          this.$toast.fail('用户基本信息修改失败');
          return;
        }else if(this.yhjbxx.nsbz==0){
          this.$toast.success('修改成功');
          await yhNsxx_remove({
            yhbh: this.yhjbxx.yhbh
          })
        }
      },
    },
  }
</script>