// WaGen___HASHKEY__561f3d42_2020-12-07 15:45:53 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
用户基本信息
*/
/* ---
标准CRUD方法：添加记录
*/
export function yhJbxx_add(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/add',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : String
*/
export function yhJbxx_update(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/update',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : String
*/
export function yhJbxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/remove',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function yhJbxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveDelete',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function yhJbxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveUpdate',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function yhJbxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveAdd',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : String
*/
export function yhJbxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/fetch',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function yhJbxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/fetchAll',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhJbxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveGet',
		method : 'post',
		data : {
			param : entity, //YhJbxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhJbxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function yhJbxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/selectiveCount',
		method : 'post',
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYWBH(meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYWBH',
		method : 'post',
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYHBH1(meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYHBH1',
		method : 'post',
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYHBH(jgbm, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYHBH',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhjbxxByYhbhJgbm(yhbh,jgbm, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhjbxxByYhbhJgbm',
		method : 'post',
		data : {
			param : {
				yhbh : yhbh, //String
				jgbm : jgbm //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhByJgbmAndYhbh(yhbh,jgbm, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhByJgbmAndYhbh',
		method : 'post',
		data : {
			param : {
				yhbh : yhbh, //String
				jgbm : jgbm //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByJgbmAndYhbh(jgbm,yhbh,jszt, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByJgbmAndYhbh',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				jszt : jszt //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByJgbmAndTqbh(jgbm,tqbh,jszt, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByJgbmAndTqbh',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh, //Integer
				jszt : jszt //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByYhbhAndNoJszt(jgbm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByYhbhAndNoJszt',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByTqbhAndNoJszt(jgbm,tqbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByTqbhAndNoJszt',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByJgbm(jgbm,jszt, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByJgbm',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				jszt : jszt //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhsjtbrzByJgbmAndNoJszt(jgbm, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhsjtbrzByJgbmAndNoJszt',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getYhdaTbgkList(jgbm,tqbh,yhbh,sftbbz,sfgxbz, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getYhdaTbgkList',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh, //Integer
				yhbh : yhbh, //String
				sftbbz : sftbbz, //String
				sfgxbz : sfgxbz //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_updateJbxxCbflFromDawh(yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/updateJbxxCbflFromDawh',
		method : 'post',
		data : {
			param : {
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_updateJbxxCbflFromDnb(fqbm,zzbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/updateJbxxCbflFromDnb',
		method : 'post',
		data : {
			param : {
				fqbm : fqbm, //String
				zzbh : zzbh //List<Long>
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_getDayhcbfl(jgbm,tqbh,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/getDayhcbfl',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh, //Long
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhJbxx_updateYhkh(yhkh,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/updateYhkh',
		method : 'post',
		data : {
			param : {
				yhkh : yhkh, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function yhJbxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/insertList',
		method : 'post',
		data : {
			param : list //List<YhJbxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : String
*/
export function yhJbxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/updateList',
		method : 'post',
		data : {
			param : list //List<YhJbxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : String
*/
export function yhJbxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhJbxx/deleteList',
		method : 'post',
		data : {
			param : list //List<YhJbxx>
		}
	})
}

